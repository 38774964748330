import React from 'react'
import ClassNames from 'classnames'

import {FaInstagram, FaSpotify, FaEtsy, FaFacebook, FaShoppingBasket} from 'react-icons/fa'
import logo from '../../static/img/logo.png'
import { Link } from 'gatsby'

class Navbar extends React.Component {
  constructor (props) {
    super(props)
    this.toggleMenuActive = this.toggleMenuActive.bind(this)
    this.state = {
      menuActive: false
    }
  }

  toggleMenuActive () {
    const currentState = this.state.menuActive
    this.setState({ menuActive: !currentState })
  };

  render () {
    var menuClass = ClassNames({
      'navbar-menu': true,
      'is-active': this.state.menuActive
    })
    var burgerClass = ClassNames({
      'navbar-burger': true,
      'is-active': this.state.menuActive
    })

    return (
      <nav className="navbar is-transparent" >
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" id="navbar-logo">
            <figure className="image">
              <img src={logo} alt="" style={{ height: '100px', width: '100px' }}/>
            </figure>
          </Link>
          <div className={burgerClass} onClick={this.toggleMenuActive} aria-label="menu" aria-expanded="false">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>
        <div className={menuClass}>
          <div className="navbar-start">
            <Link className="navbar-item" to="/about">
            About
            </Link>
            {/* <Link className="navbar-item" to="/store"> */}
            {/* Shop */}
            {/* </Link> */}
            <a
              className="navbar-item"
              target="_blank"
              rel="noopener noreferrer"
              href="https://sensitiveearth.etsy.com/">
              Shop
            </a>
            <Link className="navbar-item" to="/music">
              Music
            </Link>
            <Link className="navbar-item" to="/events">
              Events
            </Link>
            <Link className="navbar-item" to="/healing-sessions">
              Work with Me
            </Link>
            <Link className="navbar-item" to="/blog">
            Blog
            </Link>
            <Link className="navbar-item" to="/connect">
            Connect
            </Link>
          </div>
          <div className="navbar-end">
            <a
              className="navbar-item"
              href="https://open.spotify.com/artist/1k98nEawbBbQqNowdMIHFT?si=FXibA_wbSPaegcU6BQ-IYw&nd=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <FaSpotify/>
              </span>
            </a>
            <a
              className="navbar-item"
              href="https://www.instagram.com/sensitive_earth/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <FaInstagram/>
              </span>
            </a>
            <a
              className="navbar-item"
              href="https://www.etsy.com/shop/sensitiveearth"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <FaEtsy/>
              </span>
            </a>
            <a
              className="navbar-item"
              href="https://www.facebook.com/sensitiveearth"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <FaFacebook/>
              </span>
            </a>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
