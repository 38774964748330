import React from 'react'
import ClassNames from 'classnames'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { FaInstagram, FaEtsy, FaFacebook, FaHeart } from 'react-icons/fa';
import { Link } from 'gatsby'

class Footer extends React.Component {
    constructor (props) {
      super(props)
      this.state =
        { emailSubmitted: '',
          emailResponseLabel: ''
        }

      this.handleSubmitEmail = this.handleSubmitEmail.bind(this)
      this.handleEmailChange = this.handleEmailChange.bind(this)
    }

    async handleSubmitEmail (e) {
      e.preventDefault()
      const result = await addToMailchimp(this.state.emailSubmitted)
      this.setState({ emailResponseLabel: result.msg })
    }

    handleEmailChange (event) {
      this.setState({ emailSubmitted: event.target.value })
    }

    render() {
        return (
            <footer>
              <div className="columns is-size-5 is-centered content field has-padding-top">
                <div className="column is-5 has-text-centered has-text-black  ">
                  <form onSubmit={this.handleSubmitEmail}>
                    <label className="label is-size-4">
                      Open the door to welcome Earth Wisdom and offerings into your mailbox
                    </label>
                    <div className="field has-addons">
                      <div className="control is-expanded">
                        <input className="input" type="text" value={this.state.emailSubmitted} onChange={this.handleEmailChange} placeholder="Email"/>
                      </div>
                      <div className="control">
                        <input className="button" type="submit" value="Subscribe"></input>
                      </div>
                    </div>
                  </form>
                  <div dangerouslySetInnerHTML={{ __html: this.state.emailResponseLabel }}>
                  </div>
                </div>
              </div>
                <div className="columns is-size-5 is-centered content">
                  <div className="column is-3 has-text-centered has-text-black  ">
                    <Link className="has-text-black is-title-text" to="/disclaimer" >
                      Terms & Conditions
                    </Link>
                  </div>
                  <div className="column is-3  has-text-centered has-text-black  ">
                    <a className="has-text-black is-title-text" href="https://tulsileathers.net">
                      Crafted with <FaHeart/> by Tulsi
                    </a>
                </div>
                </div>
            </footer>
            )
    }
}

export default Footer
